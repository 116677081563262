import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Card, LinearProgress, Typography } from '@mui/material';
import Image from '../../../../components/image';
import { dispatch, useSelector } from '../../../../redux/store';
import EMPTY from '../../../../assets/illustrations/illustration_empty_content.svg';
import { insertDentMaterial } from '../../../../redux/slices/prothese/protheseSlice';
export default function MateriauxSection({ materiaux, selectedDent }) {
    const prothese = useSelector((state) => state.dent);
    const loading = useSelector((state) => state.material.loading);
    return (_jsxs(Card, { sx: { marginTop: '15px', overflow: 'auto', height: '500px', padding: '15px' }, children: [loading && (_jsx(Box, { sx: {
                    padding: '15px',
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: '50px',
                }, children: _jsx(LinearProgress, { color: "inherit" }) })), !loading && materiaux?.data?.length > 0 ? (_jsx(Box, { sx: {
                    display: 'grid',
                    gridTemplateColumns: {
                        sm: 'repeat(2,1fr)',
                        md: 'repeat(3,1fr)',
                        lg: 'repeat(4,1fr)',
                    },
                    gap: '5px',
                }, children: materiaux &&
                    materiaux?.data?.map((el) => {
                        const dent = prothese.dents.find((dent) => dent.name === selectedDent.toString());
                        return (_jsxs(Box, { sx: {
                                background: dent?.material === el?._id ? 'rgba(0, 184, 217, 0.16)' : '',
                                borderRadius: '10px',
                                cursor: 'pointer',
                                padding: '15px',
                            }, onClick: () => {
                                dispatch(insertDentMaterial({ name: selectedDent.toString(), materials: el?._id }));
                            }, children: [_jsx(Image, { alt: el?.image, src: el?.image || EMPTY, ratio: "16/9", disabledEffect: true, sx: { borderRadius: 1, mb: 1, border: '1px solid #006C9C' } }), _jsx(Typography, { color: 'white', sx: {
                                        textAlign: 'center',
                                        padding: '5px 10px',
                                        borderRadius: '8px',
                                        border: '1px solid #006C9C',
                                        color: 'white',
                                        background: '#006C9C',
                                        fontSize: '90%',
                                    }, children: el?.name })] }));
                    }) })) : (_jsx(_Fragment, { children: !loading && (_jsx(Box, { sx: {
                        padding: '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }, children: _jsx("img", { src: EMPTY, alt: "error" }) })) }))] }));
}
